import { withQuery, withBase } from "ufo";

export default defineNuxtRouteMiddleware(async () => {
  const { useAuth, useRest } = useCms();
  const { url } = useRest();
  const { user, setUser } = useAuth();

  if (!user.value) {
    try {
      const data = await fetch(withQuery(withBase("/users/me", url.href), {}), {
        credentials: "include",
      }).catch(() => null);

      const userData = await data
        ?.json()
        ?.then((res) => res?.data || null)
        .catch(() => null);

      if (userData) {
        setUser(userData);
      } else {
        setUser(undefined);
      }
    } catch (error) {}
  }

  if (!user.value) {
    return createError({
      statusCode: 401,
      statusMessage: "Unauthorized",
      message: "UNAUTHORIZED",
    });
  }
});
